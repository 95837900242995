<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />

    <Editor
      style="height: 500px; overflow-y: hidden"
      v-model="contentGet"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
      @onDestroyed="onDestroyed"
      @onMaxLength="onMaxLength"
      @onFocus="onFocus"
      @onBlur="onBlur"
      @customAlert="customAlert"
      @customPaste="customPaste"
    />
  </div>
</template>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

export default Vue.extend({
  components: { Editor, Toolbar },
  props: {
    content: String,
  },
  watch: {
    content: {
      handler(val) {
        console.log("大王", val);
        this.contentGet = val;
      },
    },
  },
  data() {
    return {
      // contentGet:'',
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {
        excludeKeys: [
          // 'fullScreen', // 不显示「全屏」按钮
        ],
        insertKeys: {
          index: 200, // 自定义菜单的排序
          keys: [
            "insertTable", // 插入表格
          ],
        },
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          insertTable: {
            // 表格菜单配置
            pasteTable: true,
            // 自定义过滤逻辑
            filter: (html) => html, // 根据需求添加XSS过滤
            defaultNumRows: 3, // 默认行数
            defaultNumCols: 3, // 默认列数
          },
          uploadImage: {
            customUpload: async (file, insertFn) => {
              let FormDatas = new FormData();
              FormDatas.append("editormd-image-file", file);
              this.$ajax({
                method: "post",
                url: this.url + "/imageUpload.do",
                timeout: 1000,
                headers: {
                  token: "", //接口需要的token
                  "Content-Type": "multiart/form-data",
                },

                data: FormDatas,
                TransformReques: [(data) => data],
              }).then((res) => {
                insertFn(this.url + res.data.url, "", "");
                const imgUrl = this.url + res.data.url;
                editor.insertEmbed(
                  editor.getSelection().index,
                  "image",
                  imgUrl
                );
                window.localStorage.setItem("editor_anjing", editor.getHtml());
                window.localStorage.setItem(
                  "editor_getText_anjing",
                  editor.getText()
                );

                this.contentGet = editor.getHtml();
              });
            },
            // customInsert: (res, insertFn) => {
            //   connole.log(res, insertFn)
            //   if (res.data.status != 1) {
            //     this.$message.error("上传失败!");
            //     return;
            //   }

            //   this.$message.success("上传成功!");
            // },
          },
        },
      },
      mode: "default", // or 'simple'
      // url: this.url, // 定义上传地址
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    onChange(editor) {
      console.log("onChange", editor.getText().length);
      if (editor.getText().length > 5000) {
        this.$message.info(
          "敏感信息：超出最大字数5000限制，当前字符长度为" +
            editor.html.length +
            ",将无法保存！"
        );
      }
      window.localStorage.setItem("editor_anjing", editor.getHtml());
      window.localStorage.setItem("editor_getText_anjing", editor.getText());

      this.contentGet = editor.getHtml();
    },
    onDestroyed(editor) {
      console.log("onDestroyed", editor);
    },
    onMaxLength(editor) {
      console.log("onMaxLength", editor);
    },
    onFocus(editor) {
      console.log("onFocus", editor);
    },
    onBlur(editor) {
      console.log("onBlur", editor);
    },
    customAlert(type, info) {
      window.alert(`customAlert in Vue demo\n${type}:\n${info}`);
    },
    customPaste(editor, event, callback) {
      console.log("ClipboardEvent 粘贴事件对象", event);
      const html = event.clipboardData.getData("text/html"); // 获取粘贴的 html
      const text = event.clipboardData.getData("text/plain"); // 获取粘贴的纯文本
      const rtf = event.clipboardData.getData("text/rtf"); // 获取 rtf 数据（如从 word wsp 复制粘贴）
      // 处理图片粘贴
    console.log(html)
      if (html.includes("<img")) {
        const images = html.match(/<img[^>]+src="([^">]+)"/g);
        images.forEach((imgTag) => {
          const src = imgTag.match(/src="([^"]+)"/)[1];
          if (src.startsWith("data:")) {
            console.log('okok')
            // base64图片
            const file = this.dataURLtoFile(src); // 需要实现base64转File方法
            // this.uploadImage(file, editor); // 复用现有的图片上传逻辑
          }
        });
        callback(false);
        return;
      }

      // 处理表格粘贴
      if (html.includes("<table")) {
        editor.insertHtml(html); // 直接插入表格HTML
        callback(false);
        return;
      }
      // 自定义插入内容
      editor.insertText(text);

      // 返回 false ，阻止默认粘贴行为
      event.preventDefault();
      callback(false); // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    dataURLtoFile(dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "pasted-image.png", { type: mime });
    },
   
  },
  watch: {
    content: {
      handler(val) {
        this.contentGet = val;
      },
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    // console.log(this.content)
    this.contentGet = this.content;
    // }, 1500);
  },

  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>